import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Header from 'components/Auth/Header';
import { MinusIconSvg, PlusIconSvg } from '../App/SvgIcons';

function Contact() {
  const params = useParams();
  const getScrollData = async () => {
    console.log(params.type);
    if(params.type == 'faq'){
      const element = document.getElementById('faqcontainer');
      element.scrollIntoView();
    }
  };

  useEffect(() => {
    getScrollData();
  }, []);

  return (
    <><Header /><div className="content_container about_content_container">
      <div className="about_container contact_container">
        <div className="contact_ripple">
          <img
            src="https://static-web.fra1.digitaloceanspaces.com/royalarion/contact-ripple-bg.png"
            alt="" />
        </div>
        <div className="contact_blur" />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="about_header text-center">
                <h2 className="text-gradient">Contact Us</h2>
                <p>We look forward to hearing from you!</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 founder_info">
              <div className="row">
                <div className="col-lg-6">
                  <div className="card_container">
                    {/* <h3 className="text-gradient">James O’Donnell</h3> */}
                    <h5>The Royal Arion Club (UK)</h5>
                    <p>
                      Alton House
                      <br />
                      117 High Street
                      <br />
                      Newmarket, Suffolk
                      <br />
                      CB8 9WL
                    </p>
                    <h6>Telephone</h6>
                    <span>NA</span>
                    <h6>Email</h6>
                    <a href="mailto:membersupport@royalarion.com">membersupport@royalarion.com</a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card_container">
                    {/* <h3 className="text-gradient">Surya Narreddi</h3> */}
                    <h5>The Royal Arion Club (India)</h5>
                    <p>
                      Sy No: 83/1,T-Hub Foundation,
                      <br />
                      Knowledge City, Raidurg, 
                      <br />
                      Hyderabad, Rangareddy
                      <br />
                      Telangana, 500081
                    </p>
                    <h6>Telephone</h6>
                    <span>NA</span>
                    <h6>Email</h6>
                    <a href="mailto:membersupport@royalarion.com">membersupport@royalarion.com</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row contact_content">
            <div className="contact_card_wrap col-12">
              <div className="contact_card">
                <div className="icon_wrap">
                  <img
                    src="https://static-web.fra1.digitaloceanspaces.com/royalarion/support-icon.svg"
                    alt=""
                    width="48"
                    height="48" />
                </div>
                <h5>Membership team</h5>
                <p>Write to us</p>
                <Link to="mailto:membersupport@royalarion.com">membersupport@royalarion.com</Link>
              </div>
            </div>
          </div>
        </div>
        {/* start of faq container */}
        <div id="faqcontainer" className="faq_container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="faq_heading">
                  <h3 className="text-gradient">Frequently Asked Questions </h3>
                  <p>
                    We hope that any queries you may have are answered in this section. If there is
                    anything that is not covered here that you would still like to ask, please
                    contact us on the email listed above.
                  </p>
                </div>
                <div className="faq_content">
                  <div className="faq_accordion">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          What can I expect from Club Membership?
                          <span className="action_icon plus_icon">
                            <PlusIconSvg />
                          </span>
                          <span className="action_icon minus_icon">
                            <MinusIconSvg />
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>Access to all Royal Arion Club horses through our enhanced Ownership Experience.To include visits to see the horses in training and meet the trainers at their yards.</li>
                            <li>The Royal Arion Club will purchase or lease the Club horses. When they join, members are given club shares in all horses in training whilst being treated as if they were your very own</li>
                            <li>An equal share of the prize money won by the Club horses</li>
                            <li>Racing industry editorial</li>
                            <li>Members based in India can watch live International racing for free and Indian racing for additional charge (same rate as is currently charged at Turf Authorities)</li>
                            <li>Advanced racing data, including race cards and relevant racing information</li>
                            <li>Access to all Royal Arion Club services and privileges</li>
                            <li>Access to benefits and offers from our Luxury Brand partners</li>
                            <li>Invitations to exclusive events in the UK, India and beyond</li>
                            <li>Access to our highly experienced racing managers, through our Equine Concierge Service</li>
                            <li>Founders Card Membership</li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          What events might I be invited to as a Club Member?
                          <span className="action_icon plus_icon">
                            <PlusIconSvg />
                          </span>
                          <span className="action_icon minus_icon">
                            <MinusIconSvg />
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>Through our calendar of events and in conjunction with our brand partners, Club members will be invited to an array of racing, sporting and social events</li>
                            <li>These will range from attending Royal Ascot, The Saudi Cup, The Indian Derby The Melbourne Cup and other international racing events</li>
                            <li>Our calendar also includes an array of exciting sporting events , such as the Monaco Grand Prix Superyacht event, the final of the IPL cricket or the final of the French Open Tennis.</li>
                            <li>In the cities where our horses are in training you will be invited to regular gallops / stable visits, race events, social and networking opportunities</li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          What can I expect from a Digital membership?
                          <span className="action_icon plus_icon">
                            <PlusIconSvg />
                          </span>
                          <span className="action_icon minus_icon">
                            <MinusIconSvg />
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>Access to all the Royal Arion Club horses through our web based App</li>
                            <li>A 0.1% club share in one of our specified horses in India or UK, as chosen by the member</li>
                            <li>An equal share of the prize money that your horse wins</li>
                            <li>Members based in India can watch live International racing for free and Indian racing for additional charge (same rate as is currently charged at Turf Authorities)</li>
                            <li>Visits to see the horses in training and meet the trainers at their yards, on pre-determined dates throughout the year</li>
                            <li>Racing industry editorial</li>
                            <li>Luxury partner editorial</li>
                            <li>Advanced racing data, including race cards and relevant racing information</li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          What is the 'Ownership Experience' for Club members?
                          <span className="action_icon plus_icon">
                            <PlusIconSvg />
                          </span>
                          <span className="action_icon minus_icon">
                            <MinusIconSvg />
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>As a Club Member you will receive the highest level of personal service from our Racing Managers. Each member is treated as if he or she own their horses outright.</li>
                            <li>Members will own club shares in all club horses in training (currently 6)</li>
                            <li>As such you will be treated as a racehorse owner. In fact, through our Ownership Experience, you will receive an enhanced level of information, service, interaction and involvement with the horses, trainers and jockeys</li>
                            <li>Regular updates on all the horses via the Club App, created by our inhouse team of videographers and editors</li>
                            <li>Stable visits arranged to watch your horses work on the gallops and meet the trainers</li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>
                          What happens on race days ?
                          <span className="action_icon plus_icon">
                            <PlusIconSvg />
                          </span>
                          <span className="action_icon minus_icon">
                            <MinusIconSvg />
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>
                              You will be welcomed by a Club manager at the racecourse and introduced
                              to the other members
                            </li>
                            <li>
                              They will assist you in collecting your free owners badges and show you
                              to the racecourse owners / members facilities
                            </li>
                            <li>
                              You will be escorted to the parade ring / paddock before the race to
                              meet the trainer and jockey
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5">
                        <Accordion.Header>
                          Do I receive prize money ?
                          <span className="action_icon plus_icon">
                            <PlusIconSvg />
                          </span>
                          <span className="action_icon minus_icon">
                            <MinusIconSvg />
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>Prize money will be paid as a dividend to shareholders at the end of each calendar year (pro rata based on joining date). Club members will receive prize money from all Club horses internationally. Digital members from their 1 horse.</li>
                            <li>The Royal Arion Club will retain 10% of this prize money</li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="6">
                        <Accordion.Header>
                          Will I have the opportunity to interact with other members ?
                          <span className="action_icon plus_icon">
                            <PlusIconSvg />
                          </span>
                          <span className="action_icon minus_icon">
                            <MinusIconSvg />
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>One of the benefits of being a Club member is to have the opportunity to socialise and network with other members, either at the races or at one of our many events</li>
                            <li>Through our members and partners we will build a global network of likeminded individuals</li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="7">
                        <Accordion.Header>
                          Can I take friends and family to the races and to events ?
                          <span className="action_icon plus_icon">
                            <PlusIconSvg />
                          </span>
                          <span className="action_icon minus_icon">
                            <MinusIconSvg />
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>
                              Yes, we actively encourage members to bring friends and family to the
                              races and to members events. If you intend on bringing guests, please
                              inform the Royal Arion team beforehand so we can make the necessary
                              arrangements.
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="8">
                        <Accordion.Header>
                          I live overseas, am I able to join the club ?
                          <span className="action_icon plus_icon">
                            <PlusIconSvg />
                          </span>
                          <span className="action_icon minus_icon">
                            <MinusIconSvg />
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>
                              We are a global club and as such will be opening new branches in
                              international destinations over the coming years.
                            </li>
                            <li>
                              We already have members who live all over the world and look forward to continuing to do
                              so and growing our global network
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="9">
                        <Accordion.Header>
                          What happens when the Club horses retire from racing ?
                          <span className="action_icon plus_icon">
                            <PlusIconSvg />
                          </span>
                          <span className="action_icon minus_icon">
                            <MinusIconSvg />
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>These decisions are made in conjunction with the trainer and the best
                              interests of the horse always come first.</li>
                            <li>We and our trainers have many respected 'Retraining of Racehorse' contacts to ensure these horses find
                              a new caring home and enjoy their new life after racing (showjumping,
                              polo, hacking etc).</li>
                            <li>We will continue to follow our horses in their new
                              careers and will keep members updated on their progress</li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="10">
                        <Accordion.Header>
                          Where are the horses trained?
                          <span className="action_icon plus_icon">
                            <PlusIconSvg />
                          </span>
                          <span className="action_icon minus_icon">
                            <MinusIconSvg />
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>This will change as we take on more horses and open in new locations. We currently have:</li>
                            <li>UK: 2 Newmarket, 1 Newbury (Kingsclere)</li>
                            <li>INDIA: 1 Mumbai, 2 Bangalore, 2 Hyderabad</li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end of faq container */}
      </div>
    </div></>
  );
}

export default Contact;
