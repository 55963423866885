import React, { useState } from 'react';
import { Link, useLocation, NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Header() {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="header">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="logo">
              <Link to="/">
                <img
                  src="https://static-web.fra1.digitaloceanspaces.com/royalarion/ra-logo.svg"
                  width="291"
                  height="52"
                  alt=""
                />
              </Link>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 menu_wrapper">
            <Navbar expand="md" className="menu" expanded={expanded}>
              <div className="full_width">
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  onClick={() => setExpanded(!expanded)}
                />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <NavLink className="nav-link" to="/" onClick={() => setExpanded(false)}>
                      Home
                    </NavLink>
                    {/* <NavLink className="nav-link" to="/about" onClick={() => setExpanded(false)}>
                      About
                    </NavLink> */}
                    <NavLink className="nav-link" to="/pricing" onClick={() => setExpanded(false)}>
                      Pricing
                    </NavLink>
                    <NavLink className="nav-link" to="/contact" onClick={() => setExpanded(false)}>
                      Contact
                    </NavLink>
                  </Nav>
                  <div className="member_link_wrap">
                    <Link to="/login" className="login_link" onClick={() => setExpanded(false)}>
                      Login
                    </Link>
                    <Link to="/signup" onClick={() => setExpanded(false)}>
                      Sign Up
                    </Link>
                  </div>
                </Navbar.Collapse>
              </div>
            </Navbar>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
